.banner-container {
    position: relative;
    width: 100%;
    min-height: 400px;
    overflow: hidden;
}

.bg-image {
    background-image: url('../assets/images/web_cover01.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    height: 100%;
    width: 100%;
    opacity: 0.4;
}