.iframe-container-full {
    width: 276px;
    height: 275px;
    overflow: hidden;
    position: relative;
    background: #F44336;
    border-radius: 0 10px;
    border-top: 1px solid #F44336;
}

.iframe-container-full iframe {
    transform: scale(0.6);
    transform-origin: top left;
    position: absolute;
    top: -55%;
}

.iframe-container-half {
    width: 276px;
    height: 275px;
    overflow: hidden;
    position: relative;
    background: #F44336;
    border-radius: 0 10px;
    border-top: 1px solid #F44336;
}

.iframe-container-half iframe {
    transform: scale(1);
    transform-origin: top left;
    position: absolute;
    top: -60%;
}